<template>
  <div class="setting_form !h-full">
    <add-pixel-modal ref="add_pixel_modal_v2"></add-pixel-modal>

    <div class="w-full !h-full bg-[#F7F7FC]">
        <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-[8px]">
          <div class="flex flex-col justify-between h-full">
            <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
              <PixelTableHeader :search="payload.search" @parentDebounce="debounceSearch" @openAddModal="showAddPixelModal($refs.add_pixel_modal_v2)"></PixelTableHeader>
              <PixelList></PixelList>
            </div>
            <div class="py-[0.78rem]">
              <template>
                <div class="flex px-[1rem] w-full justify-between items-center">
                  <div class="w-full flex justify-center items-center">
                    <div v-if="pagination.totalItems > pagination.limit">
                      <TablePagination
                        :clickedMethod="paginatePixels"
                        :resultCount="pagination.totalItems"
                        :itemsPerPage="pagination.limit"
                        :currentPage="pagination.page"
                        :totalPagesNumber="pagination.totalPages"
                      ></TablePagination>
                    </div>
                  </div>
                  <PerPageFilter
                    :limit="payload.limit"
                    @click="payload.limit = $event; perPageFilter()"
                  ></PerPageFilter>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {} from '@/config/config.js'
import { mapGetters, mapActions } from 'vuex'
import AddPixelModalV2 from './dialogs/AddPixelModalV2'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  components: {
    'add-pixel-modal':AddPixelModalV2,
    PixelList: ()=>import("@/views/pages/setting/pixels/PixelListV2.vue"),
    PixelTableHeader: ()=>import("@/views/pages/setting/pixels/PixelTableHeader.vue"),
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue'),
    PerPageFilter: () => import('@/ui/ui-kit/v2/PerPageFilter.vue'),
  },
  data () {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      colorWhite: '#ffffff',
      pixelID: 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id',
      pixelPlaceholder: 'Pixel ID e.g: 277632452644288',
      resultsText: 'Results Per Page',
      payload: {
        search: '',
        page: 1,
        limit: 10,
        sort: 'created_desc'
      },
      pagination: {}
    }
  },
  async created () {
    this.getSiteDetails
    const params = this.getParamsFromURL()
    this.payload.search = params.search
    this.payload.page = params.page
    this.payload.limit = params.limit
    if (this.payload.search && this.payload.search.length > 0) this.payload.page = 1
    if (this.payload.limit && this.payload.limit > 10) {
      this.resultsText = this.payload.limit + ' Results'
      this.payload.page = 1
    }
    const pagination = await this.fetchFilteredPixels(this.payload)
    this.setRetargetingPixels(pagination)
  },
  methods: {
    ...mapActions(['setCallToActionSavingDetails','fetchFilteredPixels']),
    debounceSearch (event) {
      this.$router.replace({ query: { ...this.$route.query, search: event.target.value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.search = event.target.value
      this.payload.page = 1
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        const pagination = await this.fetchFilteredPixels(this.payload)
        this.setRetargetingPixels(pagination)
      }, 600)
    },
    setRetargetingPixels (pagination) {
      this.pagination = {
        page: pagination.current_page,
        totalItems: pagination.total,
        limit: pagination.per_page,
        totalPages: pagination.last_page
      }
    },
    async paginatePixels (page) {
      this.$router.replace({ query: { ...this.$route.query, page: page } })
      this.payload.page = page
      this.pagination.page = page
      const pagination = await this.fetchFilteredPixels(this.payload)
      this.setRetargetingPixels(pagination)
    },
    async results (value) {
      this.resultsText = value + ' Results'
      this.$refs.resultsDropdown.hide()
      this.$router.replace({ query: { ...this.$route.query, limit: value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.limit = value
      this.payload.page = 1
      const pagination = await this.fetchFilteredPixels(this.payload)
      this.setRetargetingPixels(pagination)
    },
    async perPageFilter() {
      const pagination = await this.fetchFilteredPixels(this.payload)
      this.setRetargetingPixels(pagination)
    }
  },
  computed: {
    ...mapGetters([
      'getPixelList',
      'getWorkspace'
    ])
  },
  watch: {
    async 'getWorkspace._id' (value) {
     await this.perPageFilter()
    }
  }

})
</script>
