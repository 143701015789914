<template>
        <textarea
          :id="id"
          :type="type"
          :rows="rows"
          v-model="model"
          :name="name"
          :readonly="readonly"
          :disabled="disabled"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          @keydown.enter="$emit('enter')"
          @keydown="$emit('keydown', $event)"
          class="w-full font-poppins text !text-[#3C4549] bg-transparent p-0 bg-[#F4F6FA] border-[#D2D5DF] "
          @focus="labelFocused = true"
          @blur="labelFocused = false"
          style="border:none;"
          :class="{'mt-[0.625rem]': labelFocused || value }"
        ></textarea>
    
  
   
</template>

<script>
export default {
  name: 'FloatingLabelInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    rows:{
      type: Number,
      required: true,
      default:8
    },
    value: {
      type: [String, Number, Array],
      required: false,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    showIconLeft: {
      type: Boolean,
      default: false,
    },
    showIconRight: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [Number, String],
      default: 524288,
    },
    readonly: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: [Boolean, String, Number],
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    customClass: {
      type: String,
      default: 'h-[56px]'
    },
    isShowButton: {
      type: Boolean,
      default: false
    },
    errorType: {
      type: String,
      default: ''
    },
    authPageCalled: {
      type: Boolean,
      default: false
    },
    placeholder:{
      type : String,
      required:false,
    }
  },
  data() {
    return {
      labelFocused: false
    }
  },
  computed: {
    name() {
      return this.label.toLowerCase()
    },
    borderColor() {

      if(this.errorType === 'danger') {
        return {'border': '1px solid', 'border-color': '#BE185D !important'}
      }
      if(this.errorType === 'warning') {
        return {'border': '1px solid', 'border-color': '#F4B740 !important'}
      }
      if(this.errorType === 'green') {
        return {'border': '1px solid', 'border-color': '#3ABA6D !important'}
      }

      if(this.authPageCalled) {
        return this.labelFocused ?  {'border': '1px solid', 'border-color': '#2766E4 !important'} : {'border': '1px solid', 'border-color': '#D2D5DF !important'}
      }

      return this.labelFocused ?  {'border': '1px solid' ,'border-color': '#2766E4 !important'} : ''

    },
    computeClass() {
      const errorTypeColors = {
        danger: 'bg-[#FFFCFC]',
        warning: 'bg-[#FFFEF9]',
        green: 'bg-[#FBFFFA]',
      };

      const defaultColor = this.labelFocused ? 'border-[#2766E4]' : 'border-[#D2D5DF]';
      const selectedColor = errorTypeColors[this.errorType] || defaultColor;

      return selectedColor + ' ' + (this.isShowButton ? 'rounded-tl-lg rounded-bl-lg' : 'rounded-[0.5rem]') + ' ' + this.customClass + (this.disabled ? ' select-none cursor-not-allowed' : '');
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style lang="less" scoped>
input {
  height: 1.875rem !important;
}
input:focus, input:not(:placeholder-shown) {
  height: 2.063rem !important;
}
</style>
