<template>
  <b-modal
    modal-class="modal_basic addPixelModal"
    id="addPixelModalV2"
    centered
    no-close-on-backdrop
    hide-footer
    hide-header
  >
    <div class="w-full h-full">
      <div class="w-full h-full flex flex-col items-center justify-between gap-6">
        <div class="flex flex-col items-center justify-center">
          <svg
            width="38"
            height="39"
            viewBox="0 0 38 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 0.600098C18.2251 0.600098 17.596 1.22924 17.596 2.0041V4.55866L17.123 4.61777C10.3367 5.46582 4.96602 10.8367 4.11797 17.6231L4.05885 18.0961H1.504C0.729144 18.0961 0.0999985 18.7252 0.0999985 19.5001C0.0999985 20.275 0.729144 20.9041 1.504 20.9041H4.05885L4.11797 21.3771C4.96602 28.1635 10.3367 33.5344 17.123 34.3824L17.596 34.4415V36.9961C17.596 37.771 18.2251 38.4001 19 38.4001C19.7749 38.4001 20.404 37.771 20.404 36.9961V34.4415L20.877 34.3824C27.6633 33.5344 33.034 28.1635 33.882 21.3771L33.9411 20.9041H36.496C37.2709 20.9041 37.9 20.275 37.9 19.5001C37.9 18.7252 37.2709 18.0961 36.496 18.0961H33.9411L33.882 17.6231C33.034 10.8367 27.6633 5.46582 20.877 4.61777L20.404 4.55866V2.0041C20.404 1.22924 19.7749 0.600098 19 0.600098ZM17.596 7.37176V9.7801C17.596 10.555 18.2251 11.1841 19 11.1841C19.7749 11.1841 20.404 10.555 20.404 9.7801V7.37176L21.034 7.47831C26.1342 8.34089 30.1589 12.3659 31.0215 17.466L31.128 18.0961H28.72C27.9451 18.0961 27.316 18.7252 27.316 19.5001C27.316 20.275 27.9451 20.9041 28.72 20.9041H31.128L31.0215 21.5341C30.1589 26.6343 26.1342 30.6593 21.034 31.5219L20.404 31.6284V29.2201C20.404 28.4452 19.7749 27.8161 19 27.8161C18.2251 27.8161 17.596 28.4452 17.596 29.2201V31.6284L16.9659 31.5219C11.8658 30.6593 7.84108 26.6343 6.97851 21.5341L6.87195 20.9041H9.28C10.0549 20.9041 10.684 20.275 10.684 19.5001C10.684 18.7252 10.0549 18.0961 9.28 18.0961H6.87195L6.97851 17.466C7.84108 12.3659 11.8658 8.34089 16.9659 7.47831L17.596 7.37176ZM19.0005 21.6605C20.1935 21.6605 21.1605 20.6935 21.1605 19.5005C21.1605 18.3076 20.1935 17.3405 19.0005 17.3405C17.8076 17.3405 16.8405 18.3076 16.8405 19.5005C16.8405 20.6935 17.8076 21.6605 19.0005 21.6605Z"
              fill="#2961D2"
            />
          </svg>
          <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">
            {{ getAddPixel._id ? "Edit Retargeting Pixel" : "Add Retargeting Pixel" }}
          </p>

          <p class="pt-1 text !text-[14px] text-center">
            Please enter details to add new pixel.
          </p>
        </div>

        <div class="flex flex-col justify-center item-center w-full gap-3">
          <b-dropdown
            id="company"
            ref="pixel_model_menu"
            right
            class="w-full dropdown-menu-right hide_dropdown_caret  default_style_dropdown"
            :no-caret="true"
          >
            <div
              class=" justify-between px-[0.9rem] h-[56px] bg-[#F4F6FA] rounded-[8px] items-center gap-2.5 flex !w-full"
              slot="button-content">

              <div class="w-full flex justify-between items-center">
                <div>
                  <p v-if="!getAddPixel.type" class="text-[14px] text font-normal font-poppins">Select Network</p>
                  <p v-else class="text-[14px] text font-normal font-poppins">{{ getSplittedText(getAddPixel.type, '_', ' ') }}</p>
                </div>
                <span class="arrow_icon ml-auto"><i
                  class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
              </div>
            </div>
            <transition name="fade">
              <ul class="w-full text-[#202324] py-1 text-[0.875rem] shadow-lg bg-white rounded-lg font-poppins">
                <template
                  v-for="pixel in pixelList">
                  <li
                    @click="selectPixel(pixel); $refs.pixel_model_menu.hide(true)"
                    class="list_item_li !hover:bg-[#F4F6FA] !py-3"
                  >
                    <div class="flex items-center ">
                      <div class="content">
                        <div class="text">
                          {{ getSplittedText(pixel, '_', ' ') }}
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>

            </transition>
          </b-dropdown>
          <FloatingLabelInput
            id="Pixel-Name"
            @enter="validateAndStorePixel()"
            :errorType="validations.name || validations.nameLength ? 'danger' : ''"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Pixel Name"
            placeholder="Your pixel name"
            v-model="getAddPixel.name"
          >
            <template v-slot:error_message>
              <InputFieldMessage v-if="validations.name" :message="messages.name"></InputFieldMessage>
              <InputFieldMessage v-else-if="validations.nameLength" :message="messages.nameLength"></InputFieldMessage>
            </template>
          </FloatingLabelInput>
          <template v-if="getAddPixel.type === 'custom'">
            <textarea rows="8" placeholder="e.g <script> ...retargeting code...<script>" v-model="getAddPixel.pixel" type="text" class=" text-left border-[#D2D5DF] rounded-[0.5rem] bg-[#F4F6FA] p-3 " data-cy="p-script"></textarea>
          </template>

          <template v-else>
            <div v-if="getAddPixel.type === 'google'" class="checkbox-list mb-3 flex flex-col gap-3 w-full items-start justify-start">
              <Checkbox 
                id="google_adwords"
                @click="selectGooglePixelType(googlePixelsType['google_adwords'])"
                label="Google Adwords"
                :inputValue="getAddPixel.key"
                type="radio"
                labelClass="text-[#757A8A]"
              ></Checkbox>
              <Checkbox
                id="ga4"
                @click="selectGooglePixelType(googlePixelsType['ga4'])"
                label="GA4"
                :inputValue="getAddPixel.key"
                type="radio"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>

              <Checkbox
                id="universal_analytics"
                @click="selectGooglePixelType(googlePixelsType['universal_analytics'])"
                :inputValue="getAddPixel.key"
                label="Universal Analytics"
                type="radio"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>

              <Checkbox
                id="google_tag_manager"
                @click="selectGooglePixelType(googlePixelsType['google_tag_manager'])"
                :inputValue="getAddPixel.key"
                label="Google Tag Manager"
                type="radio"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>
            </div>
            <div class="flex w-full justify-end items-center">
              <p class="font-poppins text-xs font-normal">
                <a
                  v-if="!getSiteDetails.isWhiteLabel"
                  style="color: #3988fe"
                  class="ml-auto"
                  target="_blank"
                  :href="pixel_documentation_link"
                >How do i find my
                  <span style="color: #3988fe">{{ getAddPixel.type }}</span>
                  Pixel ID?</a
                >
              </p>
            </div>
            <FloatingLabelInput
              id="pixel-id"
              @enter="validateAndStorePixel()"
              :errorType="validations.pixel || validations.pixelLength ? 'danger' : ''"
              class="mb-3 w-full"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label=" Your Pixel ID"
              :placeholder="pixel_placeholder"
              v-model="getAddPixel.pixel"
            >
              <template v-slot:error_message>
                <InputFieldMessage v-if="validations.pixel" :message="messages.pixel"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.pixelLength" :message="messages.pixelLength"></InputFieldMessage>
              </template>
            </FloatingLabelInput>
          </template>
        </div>
        <div class="mb-[1.14rem] flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="!border-[1.5px] !border-[#3C4549] !text-[14px] !py-[1.142rem] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="$bvModal.hide('addPixelModalV2')"
          >
            <template v-slot:label>Close</template>
          </Button>

          <Button
            id="store-button"
            type="button"
            class="text-white bg-[#2560D7] !text-[14px] !py-[1.142rem] !rounded-[0.571rem] hover:bg-blue-700"
            buttonClass="btn-lg"
            @click.prevent="validateAndStorePixel()"
            :disabledProp="getPixelLoaders.add"
          >
            <template v-slot:label>{{
                getAddPixel._id ? "Edit" : "Add"
              }}
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {
  pixelMessages,
  pixelValidations,
  googlePixelsType,
  googlePixelsTypePlaceHolder,
  googlePixelsTypeKey
} from '../../../../../common/attributes'
import {campaignTypes, pixelTypes} from '@/state/modules/mutation-types'
import TextAreaV2 from '../../../../../ui/ui-kit/v2/TextAreaV2.vue'

export default {
  components: {
    Selectdropdown: () => import('@/ui/ui-kit/v2/Selectdropdown.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue'),
    TextAreaV2
  },
  data () {
    return {
      pixel_documentation_link: 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id',
      pixel_placeholder: 'Pixel ID e.g: 277632452644288',
      helpscout_article_id: '147',
      validations: pixelValidations,
      messages: pixelMessages,
      pixelList: ['facebook', 'twitter', 'linkedin', 'pinterest', 'quora', 'google', 'tiktok', 'custom'],
      googlePixelsType: googlePixelsType,
      googlePixelsTypePlaceHolder: googlePixelsTypePlaceHolder,
      googlePixelsTypeKey: googlePixelsTypeKey
    }
  },
  props: ['callType', 'addPixel', 'pixelListKey'],
  computed: {
    ...mapGetters([
      'getAddPixel',
      'getPixelLoaders',
      'getCampaignAdd',
      'getCampaigns'
    ])

  },
  methods: {
    ...mapActions(['storePixel']),
    async validateAndStorePixel () {
      this.validations.name = this.requiredCheck(this.getAddPixel.name)
      this.validations.pixel = this.requiredCheck(this.getAddPixel.pixel)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)

      if (result) {
        this.getAddPixel.key = (this.getAddPixel.type === 'google') ? this.googlePixelsTypeKey[this.getAddPixel.key] : ''

        let res = await this.storePixel()
        if (res && ((res.hasOwnProperty('data') && res.data.status) || (res.hasOwnProperty('_id') && res._id))) {
          this.$bvModal.hide('addPixelModalV2')

          if (this.callType === 'bio_links') {
            this.$store.commit(pixelTypes.SET_PIXEL)
            this.ctaPixelAction(res._id)
            return
          }

          if (this.getCampaigns.pixel_add_check) {
            this.$store.commit(campaignTypes.SET_CAMPAIGN_PIXEL_ADD_CHECK, false)
            this.ctaPixelAction(res._id)
          }
        }

      }
    },

    ctaPixelAction (pixelId) {
      // selection
      let selection = this.addPixel
      if (!selection[this.pixelListKey]) {
        selection[this.pixelListKey] = []
      }

      if (selection[this.pixelListKey].includes(pixelId)) {
        let index = selection[this.pixelListKey].indexOf(pixelId)
        selection[this.pixelListKey].splice(index, 1)
      } else {
        let retargetingIds = selection[this.pixelListKey]
        retargetingIds.push(pixelId)
        this.$store.commit(campaignTypes.SET_CAMPAIGN_RETARGETING_PIXEL_LIST, (JSON.parse(JSON.stringify(retargetingIds))))
      }
    },

    showBeaconModal () {
      Beacon('article', this.helpscout_article_id, {type: 'modal'})
    },

    selectPixel (pixel) {
      this.getAddPixel.type = pixel
    },

    selectGooglePixelType (key) {
      this.getAddPixel.key = this.googlePixelsType[key]
    }
  },
  watch: {
    'getAddPixel.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 255)
      }
    },
    'getAddPixel.pixel' (value) {
      if (value && value.length > 0) {
        this.validations.pixel = false
        this.validations.pixelLength = !this.maxLength(value, 2000)
      }
    },

    'getAddPixel.type' (value) {
      switch (value) {
        case 'facebook':
          this.pixel_documentation_link = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 277632452644288'
          this.helpscout_article_id = '147'
          break
        case 'twitter':
          this.pixel_documentation_link = 'https://docs.replug.io/article/148-how-to-find-my-twitter-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: nzgab'
          this.helpscout_article_id = '148'
          break
        case 'linkedin':
          this.pixel_documentation_link = 'https://docs.replug.io/article/174-how-to-find-my-linkedin-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 248483'
          this.helpscout_article_id = '174'
          break
        case 'pinterest':
          this.pixel_documentation_link = 'https://docs.replug.io/article/176-how-to-find-my-pinterest-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 2617452371077'
          this.helpscout_article_id = '176'
          break
        case 'quora':
          this.pixel_documentation_link = 'https://docs.replug.io/article/175-how-to-find-my-quora-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 8f0a82ab5218460e89cbfbc72b394c72'
          this.helpscout_article_id = '175'
          break
        case 'google':
          this.pixel_documentation_link = 'https://docs.replug.io/article/177-how-to-find-my-google-adwords-pixel-id'
          this.pixel_placeholder = `${this.googlePixelsTypePlaceHolder[this.getAddPixel.key]}`
          this.helpscout_article_id = '177'
          break
        case 'custom':
          this.pixel_documentation_link = ''
          this.pixel_placeholder = ''
      }
    },

    'getAddPixel.key' (value) {
      this.pixel_placeholder = `${this.googlePixelsTypePlaceHolder[value]}`
    }
  }
}
</script>
